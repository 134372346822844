var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v("Institusi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama_institusi))]),_c('td'),_c('th',[_vm._v("Program Studi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama_prodi))])]),_c('tr',[_c('th',[_vm._v("Kelompok")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.profesi))]),_c('td'),_c('th',[_vm._v("Tanggal Awal")]),_c('td',[_vm._v(": "+_vm._s(_vm.formatDate(_vm.data.tanggal_awal)))])]),_c('tr',[_c('th',[_vm._v("Stase/ Prodi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama)+", "+_vm._s(_vm.data.stase))]),_c('td'),_c('th',[_vm._v("Tanggal Akhir")]),_c('td',[_vm._v(": "+_vm._s(_vm.formatDate(_vm.data.tanggal_akhir)))])])])]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"options":_vm.tableOptions,"server-items-length":_vm.table.totalItems,"loading":_vm.table.loading},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Anggota Stase/Praktik")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.add()}}},'v-btn',attrs,false),on),[_vm._v(" Tambah Item ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","label":"Role :","items":_vm.form.roles},on:{"change":function($event){return _vm.getUserBelumSelesai()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getUserBelumSelesai()}},model:{value:(_vm.form.editedItem.role),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "role", $$v)},expression:"form.editedItem.role"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" No ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Nama ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Role ")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.form.users),function(item,key){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s((key + 1)))]),_c('td',[_vm._v(_vm._s(item.nama))]),_c('td',[_vm._v(_vm._s(item.role))]),_c('td',[_c('v-checkbox',{attrs:{"x-small":"","value":item.id},model:{value:(_vm.form.editedItem.selectedUsers),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "selectedUsers", $$v)},expression:"form.editedItem.selectedUsers"}})],1)])}),0)]},proxy:true}])})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)]},proxy:true},{key:"item.stase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama)+", "+_vm._s(item.stase)+" ")]}},{key:"item.tanggal_awal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_awal))+" ")]}},{key:"item.tanggal_akhir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_akhir))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getDataFromApi()}}},[_vm._v(" Reload ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }