<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">Pilih User</span>
        </v-card-title>
        <v-card-text>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                No
                            </th>
                            <th class="text-left">
                                Nama
                            </th>
                            <th class="text-left">
                                Role
                            </th>
                            <th class="text-left">
                                Profesi
                            </th>
                            <th class="text-left">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in desserts" :key="item.name">
                            <td>{{ (key + 1) }}</td>
                            <td>{{ item.nama }}</td>
                            <td>{{ item.role }}</td>
                            <td>{{ item.profesi }}</td>
                            <td>
                                <v-checkbox v-model="selected" x-small :value="item.id"></v-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="pilihItems()"> Pilih</v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import axios from 'axios'
import { mdiArrowRightBoldBox } from '@mdi/js'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    data: () => ({
        icons: {
            mdiArrowRightBoldBox,
        },
        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        search: '',

        selected: [],
        selectedAll: false,
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Tambah Anggota' : 'Edit Anggota'
        },
    },

    watch: {
        // options: {
        //     handler() {
        //     },
        //     deep: true,
        // },
    },

    created() {
        // this.initialize()
        this.getDataFromApi()
    },

    methods: {
        getDataFromApi() {
            this.loading = true
            const { page, itemsPerPage } = this.options
            // console.log(sortBy.length + ' ' + sortDesc.length + ' ' + page + ' ' + itemsPerPage)
            // 0 0 1 10
            const fmData = new FormData()
            // fmData.append('page', page)
            // fmData.append('itemsPerPage', itemsPerPage)
            fmData.append('search', this.search)
            axiosPostAuth('api/User/getUserBelumSelesai', fmData)
                .then(response => {
                    this.desserts = response.data
                })
                .catch(error => {
                    alert(error)
                })
        },
        // searching() {
        //     this.options.page = 1
        //     this.getDataFromApi()
        // },
        pilihItem(item) {
            this.$store.commit('setUserSelected', item)
        },
        pilihItems() {
            this.$store.commit('setUsersSelected', this.selected)
            this.$emit('messageFormChild', 'tutup')
        },
    },
}
</script>
  